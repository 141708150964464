import React from 'react'

export default () => (
  null
  // <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  //   <path fill-rule='evenodd' clip-rule='evenodd' d='M12 18.75C15.7279 18.75 18.75 15.7279 18.75 12C18.75 8.27208 15.7279 5.25 12 5.25C8.27208 5.25 5.25 8.27208 5.25 12C5.25 15.7279 8.27208 18.75 12 18.75ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z' fill='currentColor' />
  //   <path d='M14.9533 20.4847L5.89775 18.0582L4.43204 14.835L4.41521 12L18.9041 15.8823L18.1965 18.5232L14.9533 20.4847Z' fill='currentColor' />
  //   <path fill-rule='evenodd' clip-rule='evenodd' d='M12 18.1875C15.4173 18.1875 18.1875 15.4173 18.1875 12C18.1875 8.58274 15.4173 5.8125 12 5.8125C8.58274 5.8125 5.8125 8.58274 5.8125 12C5.8125 15.4173 8.58274 18.1875 12 18.1875ZM12 19.875C16.3492 19.875 19.875 16.3492 19.875 12C19.875 7.65076 16.3492 4.125 12 4.125C7.65076 4.125 4.125 7.65076 4.125 12C4.125 16.3492 7.65076 19.875 12 19.875Z' fill='currentColor' />
  // </svg>
)
