import React from 'react'

export default () => (
  null
  // <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  //   <path d='M4 9.5C4 8.67157 4.67157 8 5.5 8C6.32843 8 7 8.67157 7 9.5V14.5C7 15.3284 6.32843 16 5.5 16C4.67157 16 4 15.3284 4 14.5V9.5Z' fill='currentColor' />
  //   <path d='M16.3077 8.5C16.3077 7.67157 16.9793 7 17.8077 7C18.6361 7 19.3077 7.67157 19.3077 8.5V15.5C19.3077 16.3284 18.6361 17 17.8077 17C16.9793 17 16.3077 16.3284 16.3077 15.5V8.5Z' fill='currentColor' />
  //   <path d='M10.1538 5.5C10.1538 4.67157 10.8254 4 11.6538 4C12.4823 4 13.1538 4.67157 13.1538 5.5V18.5C13.1538 19.3284 12.4823 20 11.6538 20C10.8254 20 10.1538 19.3284 10.1538 18.5V5.5Z' fill='currentColor' />
  // </svg>
)
