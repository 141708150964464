import React, { Component } from 'react'
import data from './../../data.json'
import IconCheckmark from './icon-checkmark'
import IconDarkMode from './icon-dark-mode'
import IconLock from './icon-lock'
import IconLofiMode from './icon-lofi-mode'
import IconLowPassMode from './icon-low-pass-mode'
import IconMacro from './icon-macro'
import IconReverbControl from './icon-reverb-control'
import IconShare from './icon-share'
import IconSlowControl from './icon-slow-control'
import IconVideoFxControl from './icon-video-fx-control'
import Logo from './logo'

class PopupComponent extends Component {
  constructor (props) {
    super(props)

    this.range = Array.from({ length: (0.4 / 0.01) + 1 }, (_, index) => Math.round((1.0 - (index * 0.01)) * 100) / 100)
    this.rangeMarkers = this.rangeMarkers(this.range)
    this.shareUrlBox = React.createRef()
    this.state = {
      copied: false,
      mount: false,
      sliderGhost: {
        position: null,
        active: false,
        height: '80%',
        top: '20%'
      }
    }
  }

  componentDidMount () {
    setTimeout(() => this.setState({ mount: true }), 200)
  }

  rangeMarkers (range) {
    const groups = ([a, b, c, d, ...etc]) => etc.length ? [[a, b, c, d], ...groups(etc)] : [[a, b, c, d]]
    return groups(range).map((steps) => steps[0])
  }

  rangeValueIsMarker (value) {
    return this.rangeMarkers.includes(value)
  }

  onSliderHover (event) {
    const sort = Math.floor(Math.random() * 2 + 1) - 1

    this.setState({
      sliderGhost: {
        ...this.state.sliderGhost,
        height: ['70%', '60%'][sort],
        top: ['15%', '20%'][sort],
        position: event.pageX - event.target.getBoundingClientRect().x
      }
    })
  }

  onSliderDown () {
    this.setState({ sliderGhost: { ...this.state.sliderGhost, active: false } })
  }

  onSliderUp () {
    this.setState({ sliderGhost: { ...this.state.sliderGhost, active: true } })
  }

  onSliderEnter () {
    this.setState({ sliderGhost: { ...this.state.sliderGhost, active: true } })
  }

  onSliderLeave () {
    this.setState({ sliderGhost: { ...this.state.sliderGhost, active: false } })
  }

  render () {
    const {
      slowControl, isSlowedEnabled, reverbMode, reverbModes, remainingTrialDays,
      videoFx, videoFxs, error, notSupported, isHeaderVisible, isPro, trialMode,
      darkMode, lowPassMode, lofiTapeMode, trackName, url, debugMode, isFeedbackEnabled,
      isRateAppEnabled, isBuyCoffeeEnabled, isProFeaturesLocked
    } = this.props

    const { copied, sliderGhost } = this.state

    if (error) {
      return (
        <div className='popup'>
          <div className='content'>
            <p>{process.env.NODE_ENV === 'development' ? error.message : 'Something went wrong'}</p>
          </div>
        </div>
      )
    }

    const header = (
      <div className='header'>
        <div className='header__content'>
          <div className='header__left_button'>
            {!isPro && <button className='button' onClick={() => this.props.onUpgradeClick()}>Unlock PRO</button>}
          </div>

          <div className='header__title'>
            <Logo isPro={isPro} trialMode={trialMode} />
          </div>

          <div className='header__right_button'>
            <span>{notSupported ? 'NOT SUPPORTED' : (!isSlowedEnabled && this.state.mount ? 'OFF' : '')}</span>
            <input
              type='checkbox'
              className='switcher'
              checked={isSlowedEnabled}
              disabled={notSupported}
              onChange={() => this.props.onSlowedToggle(!isSlowedEnabled)}
            />
          </div>
        </div>

        <div className='header__bar' />
      </div>
    )

    return (
      <div id='Slowed' className={`${darkMode && 'dark'}`}>
        <div className={`popup ${this.state.mount ? '' : 'mounting'}`}>
          {isHeaderVisible && header}

          <div className={`content ${!isSlowedEnabled && 'content--disabled'}`}>
            {!isSlowedEnabled && <div className='content__overlay' />}

            <div className='content__item'>
              <div className='controls'>
                <div className='slow'>
                  <div className='slow__label'>
                    <IconSlowControl /> Slow Control
                  </div>
                  <div className='slow__handler'>
                    <div className='slider'>
                      <div
                        className='slider__interactor'
                        onMouseEnter={(event) => this.onSliderEnter(event)}
                        onMouseUp={(event) => this.onSliderUp(event)}
                        onMouseDown={(event) => this.onSliderDown(event)}
                        onMouseMove={(event) => this.onSliderHover(event)}
                        onMouseLeave={(event) => this.onSliderLeave(event)}
                      >
                        <div
                          className={`slider__ghost ${sliderGhost.active ? 'slider__ghost--active' : ''}`}
                          style={{ left: sliderGhost.position, height: sliderGhost.height, top: sliderGhost.top }}
                        />

                        <div className='slider__points'>
                          {this.range.map((value) => (
                            <div
                              key={value}
                              className={`slider__point ${this.rangeValueIsMarker(value) || value === slowControl ? '' : 'slider__point--hide'} ${value === slowControl && 'slider__point--active'}`}
                            >
                              <div className='slider__label'>
                                {value === 1 ? 'None' : value}
                              </div>
                            </div>
                          ))}
                        </div>

                        <input
                          type='range'
                          min='1'
                          max='1.4'
                          value={2 - slowControl}
                          step='0.01'
                          onChange={(event) => {
                            const value = parseFloat((2 - parseFloat(event.currentTarget.value)).toFixed(2))
                            this.props.onSlowControlChange(value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='content__item'>
              <div className='reverb'>
                <div className='reverb__label'>
                  <IconReverbControl /> Reverb Mode
                </div>

                <div className='reverb-items'>
                  {reverbModes.map(({ brightness, value, color, name, isPro: proFeature }) => {
                    const isLocked = proFeature && isProFeaturesLocked

                    return (
                      <a
                        key={name} className={`reverb-select reverb-item reverb-item--${brightness} ${reverbMode === value && 'reverb-item--on'} ${isLocked && 'reverb-item--locked'}`}
                        href={isLocked ? null : '#!'}
                        onClick={() => {
                          if (isLocked) return
                          this.props.onReverbModeChange(value)
                        }}
                        value={value}
                      >
                        <div className='reverb-item__bg' style={{ backgroundColor: color }}>
                          <div className='reverb-item__checkmark'>
                            <IconCheckmark />
                          </div>
                        </div>
                        <div className='reverb-item__label'>{isLocked && <IconLock />}{' '}{name}</div>
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className='content__item'>
              <div className='effect'>
                <div className='effect__label'>
                  <IconVideoFxControl /> Video Effects
                </div>

                <div className='effect-items'>
                  {videoFxs.map(({ value, name, isPro: proFeature }) => {
                    const isLocked = proFeature && isProFeaturesLocked
                    const isActive = videoFx === value

                    return (
                      <a
                        key={name} className={`video-fx-checkbox effect-item ${isActive && 'effect-item--active'} ${isLocked && 'effect-item--locked'}`}
                        href={isLocked ? null : '#!'}
                        onClick={() => {
                          if (isLocked) return
                          this.props.onVideoFxChange(videoFx === value ? null : value)
                        }}
                      >
                        <div className='effect-item__content'>
                          {isLocked && <IconLock />}
                          {name}
                        </div>
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className='content__item'>
              <div className='subcontent'>
                <div className={`other-control ${isProFeaturesLocked && 'other-control--locked'}`}>
                  <div className='other-control__label'>
                    {isProFeaturesLocked && <IconLock />}
                    {!isProFeaturesLocked && <IconLowPassMode />}
                    Low Pass Mode
                  </div>
                  <input
                    type='checkbox' className='switcher' checked={lowPassMode} disabled={isProFeaturesLocked}
                    onChange={() => this.props.onLowPassModeChange(!lowPassMode)}
                  />
                </div>

                <div className={`other-control ${isProFeaturesLocked && 'other-control--locked'}`}>
                  <div className='other-control__label'>
                    {isProFeaturesLocked && <IconLock />}
                    {!isProFeaturesLocked && <IconLofiMode />}
                    Lofi Tape Mode
                  </div>
                  <input
                    type='checkbox' className='switcher' checked={lofiTapeMode} disabled={isProFeaturesLocked}
                    onChange={() => this.props.onLofiTapeModeChange(!lofiTapeMode)}
                  />
                </div>

                <div className='other-control'>
                  <div className='other-control__label'>
                    <IconShare />
                    Share Track
                  </div>
                  <button
                    className='button'
                    onClick={() => {
                      navigator.clipboard.writeText(this.props.shareUrl({
                        slowControl,
                        reverbMode,
                        lowPassMode,
                        lofiTapeMode,
                        videoFx,
                        trackName,
                        url,
                        version: process.env.VERSION
                      }))

                      this.setState({ copied: true })
                      this.props.onCopyShareUrl()
                    }}
                  >
                    {copied ? 'Copied to Clipboard!' : 'Copy link'}
                  </button>
                </div>
              </div>
            </div>
            
            <div className='actions'>
              <div className='actions__list'>
                {!isProFeaturesLocked && <a href='#!' onClick={() => this.props.onDarkModeChange(!darkMode)}>{darkMode ? 'Light Mode' : 'Dark Mode'}</a>}
                {!isProFeaturesLocked && <a href='#!' onClick={() => this.props.onCustomizeMacro()}>Set Shortcuts</a>}
                <a href='#!' onClick={() => this.props.onHelpClick()}>Get Help</a>
                {isFeedbackEnabled && <a href='#!' onClick={() => this.props.onFeedbackClick()}>Give Feedback</a>}
                {isRateAppEnabled && <a href='#!' onClick={() => this.props.onRateAppClick()}>Rate App</a>}
                {isBuyCoffeeEnabled && !isPro && !trialMode && <a href='#!' onClick={() => this.props.onBuyCoffeeClick()}>Buy Coffee</a>}
                {debugMode && isPro && <a href='#!' onClick={() => this.props.onResetProClick()}>Reset PRO</a>}
                {debugMode && trialMode && <a href='#!' onClick={() => this.props.onEndTrialModeClick()}>End Trial Mode</a>}
              </div>
              
              <div className='actions__status'>
                {trialMode && remainingTrialDays >= 0 && `Trial expires ${remainingTrialDays > 0 ? `in ${remainingTrialDays} days` : 'today'}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PopupComponent.defaultProps = {
  slowControl: 1,
  reverbMode: 'default',
  reverbModes: data.reverbModes,
  videoFx: null,
  videoFxs: data.videoFxs,
  isSlowedEnabled: false,
  isHeaderVisible: true,
  isPro: false,
  isProFeaturesLocked: false,
  trialMode: false,
  remainingTrialDays: 0,
  debugMode: false,
  lowPassMode: false,
  lofiTapeMode: false,
  darkMode: false,
  isFeedbackEnabled: false,
  isRateAppEnabled: false,
  isBuyCoffeeEnabled: false,
  trackName: null,
  shareUrl: () => {},
  onSlowedToggle: () => {},
  onSlowControlChange: () => {},
  onVideoFxChange: () => {},
  onLowPassModeChange: () => {},
  onLofiTapeModeChange: () => {},
  onDarkModeChange: () => {},
  onReverbModeChange: () => {},
  onCopyShareUrl: () => {},
  onCustomizeMacro: () => {},
  onUpgradeClick: () => {},
  onFeedbackClick: () => {},
  onHelpClick: () => {},
  onRateAppClick: () => {},
  onBuyCoffeeClick: () => {},
  onResetProClick: () => {},
  onEndTrialModeClick: () => {}
}

self.Slowed.Components.Popup = PopupComponent

export default PopupComponent
